<template>
  <div class="w-full h-screen container mx-auto">
    <div class="w-full justify-start fixed">
      <div class="m-4">
        <span class="text-4xl font-bold text-blue-900 uppercase font-roboto mr-0.5">монитор</span>
        <span class="text-4xl font-bold text-black uppercase font-roboto">цеха</span>
      </div>
    </div>
    <div class="w-full h-full flex flex-col justify-center items-center">
      <div class="flex flex-col justify-center items-center w-1/3">
          <div class="w-full flex flex-col justify-center items-start my-4">
            <span class="font-sans font-medium  text-gray-400">Код безопасности</span>
            <input v-model="sequrecode_local" type="password" class="w-full text-xl font-medium font-sans border border-1 rounded-lg focus:outline-none border-gray-300 px-4 py-4">
          </div>
          <div class="w-full flex flex-col justify-center items-start my-4">
            <span class="font-sans font-medium  text-gray-400">Логин</span>
            <input v-model="user" type="text" class="w-full text-xl font-medium font-sans border border-1 rounded-lg focus:outline-none border-gray-300 px-4 py-4">
          </div>
          <div class="w-full flex flex-col justify-center items-start my-4">
            <span class="font-sans font-medium  text-gray-400">Пароль</span>
            <input v-model="password" type="password" class="w-full text-xl font-medium font-sans border border-1 rounded-lg focus:outline-none border-gray-300 px-4 py-4">
          </div>
          <button @click="authentication()" class="font-sans font-semibold text-xl text-white bg-blue-900 rounded-xl focus:outline-none px-5 py-3 my-4">Продолжить</button>
        </div>
    </div>
  </div>
</template>

<script>
import globalParams from "@/mixins/globalParams.mixin.js";

export default {
  name: 'Login',
  data(){
    return {
      sequrecode_local: "",
      user: "",
      password: ""
    }
  },
  mixins: [globalParams],
  methods: {
    authentication(){
        this.auth({
          securecode: this.sequrecode_local,
          user : this.user,
          password: this.password
        })
    }
  }
}
</script>



