import {createNamespacedHelpers} from "vuex";
const {mapState,mapActions, mapGetters} = createNamespacedHelpers('globalParams');

const computed = {
    ...mapState([
        'screenMode',
        'updateParametr',
        'sessionID',
        'refreshID',
        'securecode',
        'services'
    ]),
    ...mapGetters([
        'lingering',
        'overdue',
        'inWork',
        'statisticList'
    ])
}

const methods = {
    ...mapActions([
        'changeScreenMode',
        'setFrequency',
        'auth',
        'getData',
        'exit'
    ])
}

export default {
    computed,
    methods
}